import CardInfo from "./Components/CardInfo";
import ImageGallery from "./Components/ImageGallery";

function App() {
  return (
    <div className="flex flex-col items-center">
      <ImageGallery></ImageGallery>
    </div>
  );
}

export default App;
