import React from "react";

export const Card = ({ imageUrl, name }) => {
  return (
    <div className="w-60 shadow-lg rounded-md flex flex-col overflow-hidden transform transition duration-500 hover:scale-105">
      <img className="w-60 h-60 gallery-image" src={imageUrl} />
      <div className="flex h-12 items-center justify-center">{name}</div>
    </div>
  );
};
