import React from "react";

const CardInfo = (props) => {
  return (
    <div className="bg-neutral-100 h-full w-52 absolute top-0 left-0 z-10 shadow-right">
      CardInfo
    </div>
  );
};

export default CardInfo;
