import React, { useState, useEffect } from "react";
import { Card } from "./Card";

const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const getImageFilenames = async () => {
    fetch(`${process.env.PUBLIC_URL}/flowers.json`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setImages(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getImageFilenames();
  }, []);

  return (
    <div className="flex flex-col justify-center align-center m-12">
      <h2 className="m-auto p-4 text-4xl italic font-serif mb-8">Isabelita</h2>
      {!loading && (
        <div className="flex flex-row gap-8 flex-wrap justify-center">
          {images.map((image, index) => (
            <Card
              key={index}
              imageUrl={`${process.env.PUBLIC_URL}/images/${image.url}`}
              name={image.name}
            ></Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
